
import { defineComponent, computed, ref, watch, PropType } from 'vue'

import MessageBlock from '@/components/shared/message-block.vue'
import {
	ClientTransactionNames,
	IClientTransaction,
	ClientTransactionTypes,
} from '@server/api/models/fe/client'

export default defineComponent({
	props: {
		transactions: {
			type: Array as PropType<IClientTransaction[]>,
			default: () => [],
		},
		isConfigActive: Boolean,
		canEdit: Boolean,
	},
	components: {
		MessageBlock,
	},
	setup(props, context) {
		const createTransactionMap = (transactions: IClientTransaction[]) => {
			const map: Record<string, IClientTransaction> = {}
			transactions.forEach(transaction => {
				map[transaction.name] = transaction
			})
			return map
		}

		const transactionMap = ref(createTransactionMap(props.transactions))

		const canSetBatchEligibility = computed(
			() => transactionMap.value[ClientTransactionTypes.ELIGIBILITY].isActive
		)

		const canSetPAS = computed(
			() =>
				transactionMap.value[ClientTransactionTypes.PRIOR_AUTH_DETERMINATION]
					.isActive
		)

		const showInfoMessage = computed<boolean>(() => {
			const BVTransaction =
				transactionMap.value[ClientTransactionTypes.ELIGIBILITY]
			const PfrTransaction =
				transactionMap.value[ClientTransactionTypes.PATIENT_RESPONSIBILITY]

			return PfrTransaction?.isActive && !BVTransaction?.isActive
		})

		const updateTransactions = () => {
			context.emit('update:transactions', Object.values(transactionMap.value))
		}

		const handleParentToggleChange = (
			isParentActive: boolean,
			childToggle: ClientTransactionTypes
		) => {
			if (!isParentActive) {
				transactionMap.value[childToggle].isActive = false
			}
			updateTransactions()
		}

		watch(
			() => props.transactions,
			transactions => {
				transactionMap.value = createTransactionMap(transactions)
			}
		)

		return {
			transactionMap,
			showInfoMessage,
			canSetBatchEligibility,
			canSetPAS,
			ClientTransactionNames,
			ClientTransactionTypes,
			handleParentToggleChange,
			updateTransactions,
		}
	},
})
