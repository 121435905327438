var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (!!Object.keys(_vm.transactionMap).length)?_c('div',[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_vm._v(" "+_vm._s(_vm.ClientTransactionNames.get(_vm.ClientTransactionTypes.ELIGIBILITY))+" ")]),_c('div',{staticClass:"column is-one-third"},[_c('v-switch',{staticClass:"toggle-button",attrs:{"id":"benefits-eligibility-toggle","disabled":!_vm.isConfigActive,"isReadOnly":!_vm.canEdit,"color":"#f15e29","inset":""},on:{"change":function($event){return _vm.handleParentToggleChange(
						$event,
						_vm.ClientTransactionTypes.BATCH_ELIGIBILITY
					)}},model:{value:(_vm.transactionMap[_vm.ClientTransactionTypes.ELIGIBILITY].isActive),callback:function ($$v) {_vm.$set(_vm.transactionMap[_vm.ClientTransactionTypes.ELIGIBILITY], "isActive", $$v)},expression:"transactionMap[ClientTransactionTypes.ELIGIBILITY].isActive"}})],1)]),_c('div',{staticClass:"columns sub-toggle"},[_c('div',{staticClass:"column",class:{ disabled: !_vm.canSetBatchEligibility }},[_vm._v(" "+_vm._s(_vm.ClientTransactionNames.get(_vm.ClientTransactionTypes.BATCH_ELIGIBILITY))+" ")]),_c('div',{staticClass:"column is-one-third"},[_c('v-switch',{staticClass:"toggle-button",attrs:{"id":"batch-eligibility-toggle","disabled":!_vm.isConfigActive || !_vm.canSetBatchEligibility,"isReadOnly":!_vm.canEdit,"color":"#f15e29","inset":""},on:{"change":_vm.updateTransactions},model:{value:(
					_vm.transactionMap[_vm.ClientTransactionTypes.BATCH_ELIGIBILITY].isActive
				),callback:function ($$v) {_vm.$set(_vm.transactionMap[_vm.ClientTransactionTypes.BATCH_ELIGIBILITY], "isActive", $$v)},expression:"\n\t\t\t\t\ttransactionMap[ClientTransactionTypes.BATCH_ELIGIBILITY].isActive\n\t\t\t\t"}})],1)]),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_vm._v(" "+_vm._s(_vm.ClientTransactionNames.get( _vm.ClientTransactionTypes.PRIOR_AUTH_DETERMINATION ))+" ")]),_c('div',{staticClass:"column is-one-third"},[_c('v-switch',{staticClass:"toggle-button",attrs:{"id":"prior-auth-requirements-toggle","disabled":!_vm.isConfigActive,"isReadOnly":!_vm.canEdit,"color":"#f15e29","inset":""},on:{"change":function($event){return _vm.handleParentToggleChange(
						$event,
						_vm.ClientTransactionTypes.PRIOR_AUTH_SUBMISSION
					)}},model:{value:(
					_vm.transactionMap[_vm.ClientTransactionTypes.PRIOR_AUTH_DETERMINATION]
						.isActive
				),callback:function ($$v) {_vm.$set(_vm.transactionMap[_vm.ClientTransactionTypes.PRIOR_AUTH_DETERMINATION]
						, "isActive", $$v)},expression:"\n\t\t\t\t\ttransactionMap[ClientTransactionTypes.PRIOR_AUTH_DETERMINATION]\n\t\t\t\t\t\t.isActive\n\t\t\t\t"}})],1)]),_c('div',{staticClass:"columns sub-toggle"},[_c('div',{staticClass:"column",class:{ disabled: !_vm.canSetPAS }},[_vm._v(" "+_vm._s(_vm.ClientTransactionNames.get( _vm.ClientTransactionTypes.PRIOR_AUTH_SUBMISSION ))+" ")]),_c('div',{staticClass:"column is-one-third"},[_c('v-switch',{staticClass:"toggle-button",attrs:{"id":"prior-auth-submission-toggle","disabled":!_vm.isConfigActive || !_vm.canSetPAS,"isReadOnly":!_vm.canEdit,"color":"#f15e29","inset":""},on:{"change":_vm.updateTransactions},model:{value:(
					_vm.transactionMap[_vm.ClientTransactionTypes.PRIOR_AUTH_SUBMISSION]
						.isActive
				),callback:function ($$v) {_vm.$set(_vm.transactionMap[_vm.ClientTransactionTypes.PRIOR_AUTH_SUBMISSION]
						, "isActive", $$v)},expression:"\n\t\t\t\t\ttransactionMap[ClientTransactionTypes.PRIOR_AUTH_SUBMISSION]\n\t\t\t\t\t\t.isActive\n\t\t\t\t"}})],1)]),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_vm._v(" "+_vm._s(_vm.ClientTransactionNames.get( _vm.ClientTransactionTypes.PHARMACY_PRIOR_AUTH_SUBMISSION ))+" ")]),_c('div',{staticClass:"column is-one-third"},[_c('v-switch',{staticClass:"toggle-button",attrs:{"id":"pharmacy-prior-auth-submission-toggle","disabled":!_vm.isConfigActive,"isReadOnly":!_vm.canEdit,"color":"#f15e29","inset":""},on:{"change":_vm.updateTransactions},model:{value:(
					_vm.transactionMap[
						_vm.ClientTransactionTypes.PHARMACY_PRIOR_AUTH_SUBMISSION
					].isActive
				),callback:function ($$v) {_vm.$set(_vm.transactionMap[
						_vm.ClientTransactionTypes.PHARMACY_PRIOR_AUTH_SUBMISSION
					], "isActive", $$v)},expression:"\n\t\t\t\t\ttransactionMap[\n\t\t\t\t\t\tClientTransactionTypes.PHARMACY_PRIOR_AUTH_SUBMISSION\n\t\t\t\t\t].isActive\n\t\t\t\t"}})],1)]),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_vm._v(" "+_vm._s(_vm.ClientTransactionNames.get( _vm.ClientTransactionTypes.PATIENT_RESPONSIBILITY ))+" ")]),_c('div',{staticClass:"column is-one-third"},[_c('v-switch',{staticClass:"toggle-button",attrs:{"id":"patient-responsibility-toggle","disabled":!_vm.isConfigActive,"isReadOnly":!_vm.canEdit,"color":"#f15e29","inset":""},on:{"change":_vm.updateTransactions},model:{value:(
					_vm.transactionMap[_vm.ClientTransactionTypes.PATIENT_RESPONSIBILITY]
						.isActive
				),callback:function ($$v) {_vm.$set(_vm.transactionMap[_vm.ClientTransactionTypes.PATIENT_RESPONSIBILITY]
						, "isActive", $$v)},expression:"\n\t\t\t\t\ttransactionMap[ClientTransactionTypes.PATIENT_RESPONSIBILITY]\n\t\t\t\t\t\t.isActive\n\t\t\t\t"}})],1)]),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_vm._v(" "+_vm._s(_vm.ClientTransactionNames.get(_vm.ClientTransactionTypes.INSURANCE_DISCOVERY))+" ")]),_c('div',{staticClass:"column is-one-third"},[_c('v-switch',{staticClass:"toggle-button",attrs:{"id":"insurance_discovery-toggle","disabled":!_vm.isConfigActive,"isReadOnly":!_vm.canEdit,"color":"#f15e29","inset":""},on:{"change":_vm.updateTransactions},model:{value:(
					_vm.transactionMap[_vm.ClientTransactionTypes.INSURANCE_DISCOVERY].isActive
				),callback:function ($$v) {_vm.$set(_vm.transactionMap[_vm.ClientTransactionTypes.INSURANCE_DISCOVERY], "isActive", $$v)},expression:"\n\t\t\t\t\ttransactionMap[ClientTransactionTypes.INSURANCE_DISCOVERY].isActive\n\t\t\t\t"}})],1)]),(_vm.showInfoMessage)?_c('message-block',{attrs:{"message":"Ensure your client is not running transactions in Myndview, otherwise enable the Eligibility transaction or only enable PA transactions"}}):_vm._e()],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }